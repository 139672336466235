<script>
import { bus } from "@/router/bus";
import RatePageMenu from "./RatePageMenu.vue";
import RatePageHeader from "@/components/rate-card/RateCardHeader.vue";
import RatePageInfo from "./RatePageInfo.vue";
import RatePageSection from "./RatePageSection.vue";
import RatePageShipSchedule from "./RatePageShipSchedule.vue";
import TransporterCard from "../rate-card/TransporterCard.vue";
import RatePagePathTable from "./RatePagePathTable.vue";
import RatePagePathTableMobile from "./RatePagePathTableMobile.vue";
import RatePageStorage from "./RatePageStorage.vue";
import RatePageAdditional from "./RatePageAdditional.vue";
import RatePageFooter from "./RatePageFooter.vue";
import RequestForm from "../request-form/RequestForm.vue"
import AppLoader from "@/components/ui/AppLoader.vue";
import RatePageTransitInfo from "@/components/rate-page-new/statistics/RatePageTransitInfo.vue";
export default {
  name: "RatePage",

  components: {
    // Modal,
    RatePageAdditional,
    TransporterCard,
    RatePageMenu,
    RatePageHeader,
    RatePageInfo,
    RatePageSection,
    RatePageShipSchedule,
    RatePagePathTable,
    RatePagePathTableMobile,
    RatePageStorage,
    RatePageFooter,
    RequestForm,
    AppLoader,
    RatePageTransitInfo
  },

  data: () => ({
    key: 0,
    on_date: "",
    cid: -1,
    delivery_to: null,
    rate: null,
    showLoader: false,
    schedule: [],

    showOrderRequestForm: false,
    showBlockForm: false,
    rate_args: [],
    request_data: '',

    statisticData: {},

    additional: {
      detention: [],
      port_storage: [],
      port_additional: [],
      station_storage: [],
      station_additional: [],
    },

    lineInfo: null,
    showLineInfo: false,

    routerInfo: [],
    routes: [
      { id: 72, point_from_id: 72, point_from: "Москва", route_val: 0, price: 0, allow_cities: [72, 65] },
      { id: 73, point_from_id: 73, point_from: "Новосибирск", route_val: 0, price: 0, allow_cities: [73] },
      { id: 74, point_from_id: 74, point_from: "Екатеринбург", route_val: 0, price: 0, allow_cities: [74] },
      { id: 65, point_from_id: 65, point_from: "Санкт-Петербург", route_val: 0, price: 0, allow_cities: [72, 65] },
    ],

    tabs: [
      { title: 'Подробности', name: 'details', link: 'details', target: '_self', visibility: true, additionalNames: [] },
      { title: 'Расписание', name: 'schedule', link: 'schedule', target: '_self', visibility: false, additionalNames: [] },
      { title: 'Хранение контейнера', name: 'storage', link: 'storage', target: '_self', visibility: false, additionalNames: ['port_storage', 'station_storage'] },
      { title: 'Доп. услуги', name: 'additional', link: 'additional', target: '_self', visibility: false, additionalNames: ['port_additional', 'station_additional', 'detention', 'local_additional'] }
    ],

    windowWidth: window.innerWidth,
    closeIcon: require('@/assets/img/icons/cancel.svg')
  }),

  created() {
    this.key = this.$route.query.key;
    this.on_date = this.$route.query.on_date;
    this.cid = this.$route.query.cid;
    this.delivery_to = this.$route.query.delivery
    this.getRate();
  },

  beforeDestroy() {
    bus.$emit('clear-additional-children');
  },

  watch:{
    document: function(){
      this.recalcServices()
    },

    tabs: {
      handler(newVal) {
        this.tabs = newVal;
      },
      deep: true
    }
  },

  computed: {
    isAuth() {
      return !!this.$_getsetting('is_auth');
    },

    visibleTabs() {
      return Object.values(this.tabs).filter(tab => tab.visibility);
    },

    windowSize() {
      return document.documentElement.clientWidth;
    },

    userToken() {
      return this.$_getsetting('token');
    }
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);

    bus.$on("showLineInfo", () => {
      this.getLineInfo(this.rate.line_id)
    })

    bus.$on("scrollToSection", (sectionName) => {
      this.scrollToSection(sectionName)
    })
  },

  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
    },

    getRate() {
      this.showLoader = true
      const uri = this.$api + this.$methods.getRateByKey.url
      const headers = {
        headers: {
          "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.userToken}`
        }
      };

      const params = {
        key: this.key,
        on_date: this.on_date,
        client_id: this.cid
      }
      this.$postapi(uri, params, headers).then((data) => {
        if (data.error == 0) {
          this.rate = JSON.parse(
            data.message
              .replace(/:"f",/gm, `:false,`)
              .replace(/:"t",/gm, `:true,`)
          )[0];
          this.showLoader = false
          this.getRateStatistic()
          this.getAdditionalExpensesRate()
          this.getShipScheduleByKey()
          if (this.delivery_to != null) {
            this.getRoutes()
          }
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message,
          });
        }
      });
    },

    getRateStatistic() {
      const uri = this.$api + this.$methods.getRateStatisticByKey.url
      const headers = {
        headers: {
          "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.userToken}`
        }
      };

      const params = {
        key: this.rate.key_orig
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error === 0) {
          this.statisticData = JSON.parse(data.message)
        }
      })
    },

    getRoutes() {
      for ( let el of this.routes ) {
        if (el.allow_cities.includes(Number(this.rate.station_id))) {
            this.getRoute(el)
          }
        }
    },

    getRoute(item) {
      this.$postapi(this.$address + this.$getters.getRoute.uri, {
        method: this.$getters.getRoute.name,
        place_from: item.point_from,
        place_to: this.delivery_to,
      }).then(data => {
        if (data.error == 0) {
          this.routerInfo = JSON.parse(data.message)
          item.route_val = this.routerInfo.kilometers
          if(item.id == 72) {
            this.getAutoServiceInfoMoscow(item)
          } else if (item.id === 65) {
            this.getAutoServiceInfoRegion(item)
          } else {
            this.getApproximatePrice(item)
          }
        }
      });
    },


    getAutoServiceInfoMoscow(item) {
      this.deliveryPriceInfo = []
      const uri = this.$api + this.$methods.getAutoServiceInfo.url
      const headers = {
        headers: {
          "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.userToken}`
        }
      };

      const params = {
        distance: item.route_val,
        unit_type: this.rate.unit_type,
        weight: 0,
        is_vtt: 0,
        is_moscow: 1,
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error == 0) {
          this.deliveryPriceInfo = JSON.parse(data.message)
          let price = 0
          for (let i = 0; i < this.deliveryPriceInfo.length; i++) {
            price = price + this.deliveryPriceInfo[i].cost
          }
          item.price = price
          this.changeRatePrice({route: item})
        }
      });
    },

    getAutoServiceInfoRegion(item) {
      this.deliveryPriceInfo = []
      const uri = this.$api + this.$methods.getAutoServiceInfoRegion.url
      const headers = {
        headers: {
          "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.userToken}`
        }
      };
      const params = {
        place_to: this.delivery_to,
        place_from: item.point_from,
        unit_type: this.rate.unit_type,
        place_across: this.delivery_to + ';Москва',
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error == 0) {
          this.deliveryPriceInfo = JSON.parse(data.message)
          item.price = this.deliveryPriceInfo.price
          this.changeRatePrice({route: item})
        }
      });
    },

    getApproximatePrice(item) {
      this.deliveryPriceInfo = []
      const uri = this.$api + this.$methods.getApproximatePrice.url
      const headers = {
        headers: {
          "Accept": "application/json",
            "Content-Type": "application/json",
        }
      };

      const params = {
        v_place: item.point_from,
        v_place_to: this.delivery_to,
        v_unit_type: this.rate.unit_type,
        v_station_id: 0,
        v_place_to_id: item.id,
        v_transporter_id: 0,
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error == 0) {
          this.deliveryPriceInfo = JSON.parse(data.message)
          item.price = this.deliveryPriceInfo.price
          this.changeRatePrice({route: item})
        }
      });
    },

    getTotalOfRate() {
      let result = 0;
      for (let item of this.rate.detail) {
        if (isNaN(item.summa) === false) {
          result = result + item.summa * Math.round(item.cbr_rate)
        }
      }
      this.rate.total = Math.round(result)
      this.rate.all_time ++
    },

    changeRatePrice(data) {
      let chk = 0
        if (this.rate.across_place_name_id == data.route.point_from_id || this.rate.base_place_to == data.route.point_from_id) {
          for (let item of this.rate.detail) {
            if (item.type_id == 1222) {
              item.summa = data.route.price
              item.cost = data.route.price
              item.ext_place_to = ''
              item.place_to_id = ''
              item.place_to = this.delivery_to
              item.cbr_rate = 1
              item.cur_code = "RUB"
              chk = 1
              break
            }
          }
          if (chk == 0) {
            this.rate.detail.push({
              summa: data.route.price,
              cost: data.route.price,
              type_id: 1222,
              cbr_rate: 1,
              service: "Авто",
              ext_place_from: "",
              place_from: data.route.point_from,
              ext_place_to: "",
              place_to: this.delivery_to,
              icon: "4.png",
              place_to_id: '',
              transporter_name: '',
              cur_code: 'RUB'
            })
          }

          if (this.rate.across_place_name_id == data.route.point_from_id) {
            this.rate.title = this.rate.base_place_from_name + ' - ' +this.rate.across_place_name + ' - ' + this.delivery_to
          } else {
            this.rate.title = this.rate.base_place_from_name + ' - ' + this.rate.across_place_name + ' - ' + this.rate.base_place_to_name + ' - ' + this.delivery_to
          }
        this.getTotalOfRate(this.rate)
      }
    },

    getLineInfo(line_id) {
      const uri = this.$api + this.$methods.getTransporterInfoById.url
      const headers = {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        }
      };

      const params = {
        line_id: line_id
      }
      this.$postapi(uri, params, headers)
      .then((data) => {
        if (data.error == 0) {
          if (JSON.parse(data.message)[0]) {
            this.lineInfo = JSON.parse(data.message)[0]
            this.showLineInfo = true
          }
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message,
          });
        }
      });
    },

    getAdditionalExpensesRate() {
      const uri = this.$api + this.$methods.getAdditionalExpensesRate.url
      const headers = {
        headers: {
          "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.userToken}`
        }
      };

      const params = {
        key: this.key
      }
      this.$postapi(uri, params, headers)
      .then((data) => {
        if (data.error == 0) {
          this.additional = JSON.parse(
            data.message
              .replace(/:"f",/gm, `:false,`)
              .replace(/:"t",/gm, `:true,`)
          );
          this.getAdditionalHeaders(this.additional.detention, 'detention')
          this.getAdditionalHeaders(this.additional.port_storage, 'port_storage')
          this.getAdditionalHeaders(this.additional.station_storage, 'station_storage')
          this.getRateMenu()
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message,
          });
        }
      });
    },

    getRateMenu() {
      if (Number(this.rate.is_schedule) === 1) {
        this.tabs[1].visibility = true;
      }
      this.tabs[2].additionalNames.forEach((name) => {
        if (this.additional[name].length > 0) {
          this.tabs[2].visibility = true
        }
      })
      this.tabs[3].additionalNames.forEach((name) => {
        if (this.additional[name].length > 0) {
          this.tabs[3].visibility = true
        }
      })

      bus.$emit("addMenu", {
        tabs :this.tabs,
        title: this.rate.title.replace('(' + this.rate.unit_code + ')', ''),
      })
    },

    getAdditionalHeaders(array, name){
      const result = [];
      let currentGroup = null;

      array.forEach(item => {
        if (item.comment !== "") {
          currentGroup = {
            comment: item.comment,
            child: [item]
          };
          result.push(currentGroup);
        } else if (currentGroup) {
          currentGroup.child.push(item);
        } else {
          currentGroup = {
            comment: "",
            child: [item]
          };
          result.push(currentGroup);
        }
      });

      this.additional[name] = result
    },

    getShipScheduleByKey() {
      const uri = this.$api + this.$methods.getShipScheduleByKey.url
      const headers = {
        headers: {
          "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.userToken}`
        }
      };

      const params = {
        key: this.key,
        on_date: this.on_date,
        unit_type: 0
      }
      this.$postapi(uri, params, headers)
      .then((data) => {
        if (data.error == 0) {
          this.schedule = JSON.parse(
            data.message
              .replace(/:"f",/gm, `:false,`)
              .replace(/:"t",/gm, `:true,`)
          );
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message,
          });
        }
      });
    },

    async print() {
      window.print();
    },

    buyCard () {
      this.rate_args = {
          rate: this.rate
        }
      this.showOrderRequestForm = true
    },

    scrollToSection(sectionId) {
      const element = this.$refs[sectionId];
      if (element && element.getBoundingClientRect) {
        const rect = element.getBoundingClientRect();
        const offset = window.innerWidth > 1200? -150 : -50;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const top = rect.top + scrollTop + offset;

        window.scrollTo({
          top: top,
          behavior: 'smooth'
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="rate-loader-block" v-if="showLoader === true">
      <AppLoader :size="80"/>
    </div>
    <div class="rate-page" id="rate-print-page" v-else-if="rate != null">
      <RatePageMenu class="rate-page__menu" :tabs="visibleTabs" @scrollToSection="scrollToSection"/>
      <RatePageHeader v-if="rate != null" class="rate-page__header" :rate="rate" :ratePage="true" :type="'rate-page'"/>
      <RatePageInfo :rate="rate"/>

      <div ref="details" class="rate-page__link">
      </div>

      <RatePageSection
        title="Подробности"
        class="rate-page__section"
      >
        <RatePagePathTable
          class="rate-page__section__path-desktop"
          :rate="rate"
          :schedule="schedule"
          :statisticData="statisticData"
        />
        <RatePagePathTableMobile
          v-if="rate != null && windowWidth < 744"
          class="rate-page__section__path-mobile"
          :rate="rate"
          :schedule="schedule"
          :statisticData="statisticData"
        />
      </RatePageSection>

      <div ref="schedule" class="rate-page__link">
      </div>
      <RatePageSection
        v-if="Number(rate.is_schedule) === 1"
        title="Расписание"
        class="rate-page__section"
      >
        <RatePageShipSchedule
          :rate="rate"
          :is-auth="isAuth"
          :schedule="schedule"
        />
      </RatePageSection>

      <RatePageSection
        title="Транзитные сроки, дней"
        class="rate-page__section"
        v-if="rate != null"
      >
        <RatePageTransitInfo :rate="rate" :statisticData="statisticData"/>
      </RatePageSection>

      <div ref="storage" class="rate-page__link">
      </div>
      <RatePageSection
        v-if="additional.station_storage.length > 0"
        title="Хранение на станции прибытия"
        class="rate-page__section"
      >
        <RatePageStorage :additional="additional.station_storage"/>
      </RatePageSection>

      <RatePageSection
        v-if="additional.port_storage.length > 0"
        title="Хранение в порту прибытия"
        class="rate-page__section"
      >
        <RatePageStorage :additional="additional.port_storage"/>
      </RatePageSection>

      <RatePageSection
        v-if="additional.detention.length > 0"
        title="Сверхнормативное использование контейнера"
        class="rate-page__section"
      >
        <RatePageStorage :additional="additional.detention" :is_detention="true"/>
      </RatePageSection>

      <div ref="additional" class="rate-page__link">
      </div>
      <RatePageSection
        v-if="additional.station_additional.length > 0"
        title="Дополнительные услуги на станции прибытия"
        class="rate-page__section"
      >
        <RatePageAdditional :additional="additional.station_additional"/>
      </RatePageSection>

      <RatePageSection
        v-if="additional.port_additional.length > 0"
        title="Дополнительные услуги в порту прибытия"
        class="rate-page__section"
      >
        <RatePageAdditional :additional="additional.port_additional"/>
      </RatePageSection>

      <RatePageFooter
        class="rate-page__footer"
        @printPage="print"
      />

      <button class="rate-page__save-button" v-ripple @click="buyCard">
        Оформить
      </button>

      <v-dialog
        v-model="showOrderRequestForm"
        :showModal="showOrderRequestForm"
        persistent
        transition="none"
        overlay-color="rgba(67, 79, 91, 0.80)"
        style="border-radius: 32px !important; box-shadow: none !important"
        max-width="1400px"
        :width="windowSize > 1200 ? '85%' : '100%'"
        :height="windowSize > 1200 ? '95%' : '100%'"
      >
        <RequestForm
          v-if="showOrderRequestForm"
          class="request-form"
          :args="rate_args"
          :date="on_date"
          @close="showOrderRequestForm = false"
        />
      </v-dialog>

      <v-dialog
        v-model="showLineInfo"
        persistent
        transition="dialog-bottom-transition"
        @click:outside="showLineInfo = false"
      >
        <TransporterCard @close="showLineInfo = false" :args="lineInfo"/>
      </v-dialog>

    </div>

  </div>
</template>

<style scoped lang="scss">
.rate-loader-block {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 100px auto;

  @media print {
    margin: 10px auto;
  }
}

.rate-page {
  width: 100%;
  height: 100%;
  position: relative;

  &__menu {
    position: sticky;
    top: 90px;
    width: 100%;
    background: var(--grey-10);
    z-index: 10;

    @media (max-width: $tablet-width) {
      display: none;
    }

    @media print {
      display: none;
    }
  }

  &__header {
    width: 100%;
    margin: 28px 0 22px;
    background: #FFFFFF;
    border-radius: 24px;
    padding: 30px 40px;

    @media (max-width: $tablet-width) {
      padding: 24px;
      border-radius: 12px;
      margin-top: 0;
    }

    @media (max-width: $mobile-width) {
      border-radius: 0;
      border-bottom: 1px solid var(--grey-50);
      padding: 16px 16px 24px;
      margin-top: 44px;
      width: calc(100% + 32px);
      margin-left: -16px;
    }

    @media print {
      margin-top: 0;
    }
  }

  &__section {
    margin: 32px 0;
    overflow: hidden;

    @media print {
      margin: 10px 0;
    }

    &__path-desktop {
      display: block;

      @media print {
        display: block !important;
      }

      @media (max-width: $mobile-width) {
        display: none;
      }
    }

    &__path-mobile {
      display: none;

      @media print {
        display: none !important;
      }

      @media (max-width: $mobile-width) {
        display: block;
      }
    }
  }

  &__footer {
    margin: 120px 30px 60px;

    @media print {
      display: none;
    }
  }

  &__save-button {
    border-radius: 16px;
    position: fixed;
    right: 141px;
    bottom: 30px;
    background: var(--orange-70);
    color: #FFFFFF;
    padding: 16px 43px;

    @include font-description-semibold-0;

    @media (max-width: $tablet-width) {
      right: 16px;
      bottom: 20px;
    }

    @media print {
      display: none;
    }
  }
}

.request-form {
  width: 100%;
  height: 100%;
  overflow: auto;

  @media (max-width: $tablet-width) {
    height: 100%;
    width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
}

.card-main-text {
  font-size: 14px;
  color: black !important;

  &__list {
    list-style: disc !important;
    padding-left: 15px !important;
  }

  &__item-link {
    font-weight: 600;

    @include media-hover('&:active'){
      text-decoration: unset;
    }
  }
}
</style>

